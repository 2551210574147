var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.profile
        ? _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "pb-1 justify-content-start" },
                [
                  _c("b-col", { attrs: { md: "6", sm: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      [
                        _c("profile-info", {
                          attrs: { id: _vm.id, profile: _vm.profile },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-md-right text-sm-left btn-content",
                      attrs: { md: "6", sm: "12" },
                    },
                    [
                      _c(
                        "b-dropdown",
                        { staticClass: "mr-2", attrs: { text: "Mensagem" } },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "data-test": "driver-header__select-sms",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.newMessage()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-comments" }),
                              _vm._v(" SMS + Email "),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.$regions.isBrazil()
                        ? _c(
                            "b-dropdown",
                            {
                              staticClass: "mr-2",
                              attrs: { text: "Solicitar CNH" },
                            },
                            [
                              _c("b-dropdown-item", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-link" }),
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.generateUploadCNHLink.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Copiar Link")]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("b-dropdown-item", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-qrcode" }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-qrcode",
                                            modifiers: { "modal-qrcode": true },
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return (() =>
                                              _vm.generateUploadCNHLink(
                                                false
                                              )).apply(null, arguments)
                                          },
                                        },
                                      },
                                      [_vm._v(" Abrir QRCode ")]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm.findGroups("ACL:DRIVER_DOCUMENT:ADD_STATUS")
                                ? _c("b-dropdown-item", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "row",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-warning",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.newShipment()
                                              },
                                            },
                                          },
                                          [_vm._v('Ativar flag "invalid"')]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.driverEditProfilePage
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.goTo()
                                },
                              },
                            },
                            [_c("span", [_vm._v("Editar")])]
                          )
                        : _vm._e(),
                      _vm.userCanRunBGC
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.left",
                                  modifiers: { hover: true, left: true },
                                },
                              ],
                              attrs: {
                                title: _vm.getBGCErrorMessage(),
                                disabled:
                                  _vm.driverDataAllowedForBGC &&
                                  _vm.stepsAllowedForBGC,
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    id: "bgc-button",
                                    disabled: !(
                                      _vm.driverDataAllowedForBGC &&
                                      _vm.stepsAllowedForBGC
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendToBGC()
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("Enviar para o BGC")])]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasBookCar
                        ? _c("fleet-group-buttons", {
                            attrs: { id: _vm.bookingCarId, vin: _vm.vin },
                          })
                        : _vm._e(),
                      _c("fleet-lock-unlock-button", {
                        attrs: { id: _vm.bookingCarId, vin: _vm.vin },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pt-3 pb-2", attrs: { md: "12", sm: "12" } },
                    [_c("profile-nav")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("contact-driver", { attrs: { modal_data: _vm.contact_modal_data } }),
      _c(
        "b-modal",
        {
          staticClass: "modal-qrcode",
          attrs: { id: "modal-qrcode", title: "Acesse link via QRCode" },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    { attrs: { variant: "primary" }, on: { click: _vm.close } },
                    [_vm._v(" Fechar ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.link,
                  expression: "link",
                },
              ],
              staticClass: "mt-5",
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "text-align": "center",
                "align-items": "center",
              },
            },
            [
              _c("canvas", {
                ref: "canvas",
                staticStyle: { "text-align": "center" },
              }),
              _c("span", [_vm._v("Para subir o documento, escaneie o QRCode")]),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.copyImage },
                },
                [
                  _c("i", { staticClass: "fa fa-clone" }),
                  _vm._v(" Copiar QRCode "),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div v-if="profile">
      <b-row class="pb-1 justify-content-start">
        <b-col md="6" sm="12">
          <div class="d-flex flex-column">
            <profile-info
              :id="id"
              :profile="profile"
            />
          </div>
        </b-col>

        <b-col md="6" sm="12" class="text-md-right text-sm-left btn-content">
          <b-dropdown
            class="mr-2"
            text="Mensagem"
          >
            <b-dropdown-item data-test="driver-header__select-sms" @click="newMessage()">
              <i class="fa fa-comments" />
              SMS + Email
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown
            v-if="$regions.isBrazil()"
            class="mr-2"
            text="Solicitar CNH"
          >
            <b-dropdown-item>
              <div style="display: flex; flex-direction: row;">
                <i class="fa fa-link" />
                <span @click.stop="generateUploadCNHLink">Copiar Link</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div style="display: flex; flex-direction: row;">
                <i class="fa fa-qrcode" />
                <span v-b-modal.modal-qrcode @click.stop="() => generateUploadCNHLink(false)">
                  Abrir QRCode
                </span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="findGroups('ACL:DRIVER_DOCUMENT:ADD_STATUS')">
              <div style="display: flex; flex-direction: row;">
                <i class="fa fa-warning" />
                <span @click.stop="newShipment()">Ativar flag "invalid"</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>

          <b-button
            v-if="!driverEditProfilePage"
            class="mr-2"
            @click.stop="goTo()"
          >
            <span>Editar</span>
          </b-button>

          <span
            v-if="userCanRunBGC"
            v-b-tooltip.hover.left
            :title="getBGCErrorMessage()"
            :disabled="driverDataAllowedForBGC && stepsAllowedForBGC"
          >
            <b-button
              id="bgc-button"
              :disabled="!(driverDataAllowedForBGC && stepsAllowedForBGC)"
              class="mr-2"
              @click="sendToBGC()"
            >
              <span>Enviar para o BGC</span>
            </b-button>
          </span>

          <fleet-group-buttons v-if="hasBookCar" :id="bookingCarId" :vin="vin" />
          <fleet-lock-unlock-button :id="bookingCarId" :vin="vin" />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12" sm="12" class="pt-3 pb-2">
          <profile-nav />
        </b-col>
      </b-row>
    </div>
    <contact-driver :modal_data="contact_modal_data" />
    <b-modal id="modal-qrcode" class="modal-qrcode" title="Acesse link via QRCode">
      <div v-show="link" style="display: flex; flex-direction: column; text-align: center; align-items: center;" class="mt-5">
        <canvas ref="canvas" style="text-align: center;" />
        <span>Para subir o documento, escaneie o QRCode</span>
        <b-button variant="outline-primary" class="mt-4" @click="copyImage">
          <i class="fa fa-clone" />
          Copiar QRCode
        </b-button>
      </div>
      <template #modal-footer>
        <b-button @click="close" variant="primary">
          Fechar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ProfileInfo from '@components/drivers/profile/profile-info'
import ProfileNav from '@components/drivers/profile/profile-nav'
import FleetGroupButtons from '@components/fleet/fleet-group-buttons';
import ContactDriver from '@components/modals/contact-driver';
import { formatStatus } from '@utils/driver';
import { emptyObject } from '@utils/validation';
import { mapActions, mapGetters } from 'vuex';
import FleetLockUnlockButton from "@components/fleet/fleet-lock-unlock-button.vue";
import { steps } from '@entities/driver'
import RUN_BGC from '@graphql/driver/mutations/run-bgc.gql';
import { makeMutation } from "@graphql/middleware";
import EventBus from '@utils/eventBus';
import QRCode from 'qrcode';

export default {
	name: 'DriverHeader',
	components: {
		ProfileNav,
		ProfileInfo,
		FleetGroupButtons,
    FleetLockUnlockButton,
    ContactDriver,
	},
	props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    modal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      link: '',
      loadingLink: false,
      contact_modal_data: {
        id: null,
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters('driver', {profile: 'header'}),
    ...mapGetters('user', ['attributes', 'findGroups']),
    hasBookCar () {
      if (!this.profile || emptyObject(this.profile) || emptyObject(this.profile.booking) || emptyObject(this.profile.booking.car)) return false
      return true
    },
    bookingCarId () {
      if (!this.hasBookCar) return ''
      return this.profile.booking.car.id
    },
    vin () {
      if (!this.hasBookCar) return ''
      return this.profile.booking.car.vin
    },
    driverEditProfilePage () {
      return (this.$route.name === 'DriverProfileEdit') ? true : false
    },
    stepsAllowedForBGC() {
      if ([
          steps.LEAD,
          steps.NO_OPERATION,
          steps.PROSPECT,
          steps.DOCS_PENDING,
        ].includes(this.profile.step)) return true
      return false
    },
    driverDataAllowedForBGC(){
      if (this.checkField(this.profile.cpf) &&
          this.checkField(this.profile.phone_number) &&
          this.checkField(this.profile.address_latlong)) return true
      return false
    },
    userCanRunBGC(){
      return this.findGroups('ACL:DRIVER:BUTTON:BGC') || this.findGroups('ACL:GOD')
    },
  },
	mounted () {
    this.loadProfile();
    EventBus.$on('GENERATE_QRCODE', () => {
      this.generateUploadCNHLink(false)
      this.$bvModal.show('modal-qrcode');
    });
	},
	methods: {
    ...mapActions('driver', ['clearHeader', 'header']),
    ...mapActions('driverDocument', ['getDriverDocumentStatusDeploy']),
		async loadProfile() {
      this.clearHeader();
      this.header({
        id: this.id
      });
      await this.getDriverDocumentStatusDeploy(this.id);
		},
    newMessage() {
      this.contact_modal_data.id = this.id;
      this.$root.$emit('bv::close::modal');
      this.$root.$emit('bv::show::modal', 'modalContact');
    },
		setStatusBadge(status) {
      return formatStatus(status);
    },
    newShipment() {
      this.loading = true
      this.$fleetRegulationProvider.put(`/drivers/${this.id}/license/invalidate?flag=invalid`, {})
        .then(() => {
          this.showSuccessModal('Flag "invalid" ativada com sucesso!')
        })
        .catch(() => {
          this.showErrorModal('Falha ao ativar flag "invalid"!')
        })
        .finally(() => {
          this.loading = false
        })
    },
    goTo() {
      this.$router.push({
        path: `/drivers/profile/${this.id}/edit`,
      });
    },
    sendToBGC() {
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });
      confirmationWindow({
        title: "Tem certeza que deseja enviar esse cadastro para o BGC?",
        // eslint-disable-next-line max-len
        text: "Essa ação não pode ser desfeita e só pode ser executada uma vez por cadastro, certifique de que os dados estão corretos antes de confirmar.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then(result => {
        if (!result.value) return;
        makeMutation(
          RUN_BGC,
          { id: this.id }
        ).then(() => {
            this.showSuccessModal('Sucesso!!', 'Obs: O BGC pode demorar até alguns minutos, atualize sua tela caso o step do cadastro não tenha mudado')
            this.$emit('refresh')
        }).catch(() => {
            this.showErrorModal('Tente novamente mais tarde!')
          })
      })
    },
    showSuccessModal(msg, text) {
      this.$swal({
        type: 'success',
        title: msg,
        text: text,
        showConfirmButton: false,
        timer: 15000,
      })
    },
    showErrorModal(msg) {
      this.$swal({
        type: 'error',
        title: 'Ocorreu um erro!',
        text: msg,
        showConfirmButton: false,
        timer: 15000,
      });
    },
    getBGCErrorMessage() {
      let msg
      if (!this.stepsAllowedForBGC) return `Não é possível chamar o BGC para motoristas no step ${this.profile.step}`
      if (!this.driverDataAllowedForBGC) {
        let missingFields = []
        if (!this.checkField(this.profile.cpf)) missingFields.push('CPF')
        if (!this.checkField(this.profile.phone_number)) missingFields.push('Telefone')
        if (!this.checkField(this.profile.address_latlong)) missingFields.push('Endereço')

        msg = "O campo ";
        if (missingFields.length === 1) {
        msg += missingFields[0] + " é obrigatório para chamar o BGC";
        } else if (missingFields.length > 1) {
        msg += missingFields.slice(0, -1).join(', ') + " e " + missingFields.slice(-1) + " são obrigatórios para chamar o BGC";
        }
      }
      return msg
    },
    checkField(field) {
      return !!field
    },
    async generateUploadCNHLink (copyLink = true) {
      try {
        this.loadingLink = true;
        const { data } = await this.$fleetRegulationProvider.post(`driver-token/generate/${this.profile.id}`, {})
        this.link = `https://motorista.kovi.com.br/driver/document-upload/${data}`
        if (copyLink) this.copy(this.link)
        if (!copyLink) this.generateQRCode();
        this.loadingLink = false;
        return this.link
      } catch (err) {
        this.loadingLink = false;
      }
    },
    copy(link) {
      navigator.clipboard.writeText(link).then(() => {
        this.showSuccessModal('Link Copiado')
      }).catch(err => {
        console.error('Falha ao copiar o texto: ', err);
      });
    },
    generateQRCode() {
      const canvas = this.$refs.canvas;
      QRCode.toCanvas(canvas, this.link, {
        width: 400,
        margin: 1,
        color: {
          dark: '#000000',  // QR code color
          light: '#ffffff'  // Background color
        }
      }, (error) => {
        if (error) console.error(error);
        console.log('QR code generated!');
      });

      // Draw logo on the center of the QR code
      const context = canvas.getContext('2d');
      const logo = new Image();
      logo.src = '/img/brand/logo-square.svg';

      logo.onload = function () {
        const logoSize = 400 * 0.2; // Logo size is 20% of the QR code size
        const x = (canvas.width - logoSize) / 2;
        const y = (canvas.height - logoSize) / 2;
        context.drawImage(logo, x, y, logoSize, logoSize);
      };
    },
    copyImage() {
      const canvas = this.$refs.canvas;
      canvas.toBlob(blob => {
        // eslint-disable-next-line no-undef
        const item = new ClipboardItem({ 'image/png': blob });
        navigator.clipboard.write([item]).then(() => {
          this.$snotify.success(this.$t('payments.labels.clipboardSuccess'));
        }).catch(() => {
          this.$snotify.error(this.$t('payments.labels.clipboardError'));
        });
      }, 'image/png');
    },
    close () {
      this.$bvModal.hide('modal-qrcode');
    }
	}
}
</script>

<style>
.card-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.btn-content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
#modal-qrcode > .modal-dialog {
  max-width: 95% !important;
  top: 10px !important;
  bottom: 10px !important;
  left: 10px !important;
  right: 10px !important;
  height: 90vh !important;
  display: flex !important;
  position: fixed !important;
  z-index: 100000 !important;
}
</style>
